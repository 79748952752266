import type { AppRouteModule } from '@/router/types'

import { LAYOUT } from '@/router/constant'
import { t } from '@/hooks/web/useI18n'

const dashboard: AppRouteModule = {
  path: '/home',
  name: 'Home',
  component: LAYOUT,
  parentId: 0,
  redirect: '/dashboard/home',
  meta: {
    orderNo: 10,
    icon: 'home',
    title: '首页',
  },
  
  children: [
    {
      path: 'home',
      name: 'Home',
      component: () => import('@/views/dashboard/home/index.vue'),
      meta: {
        // affix: true,
        title:'首页',
        icon: 'ant-design:bar-chart-outlined',
      },
    },
    // {
    //   path: 'workbench',
    //   name: 'Workbench',
    //   component: () => import('@/views/dashboard/workbench/index.vue'),
    //   meta: {
    //     title: t('routes.dashboard.workbench'),
    //     icon: 'ant-design:appstore-outlined'
    //   }
    // }
  ],
}

export default dashboard
