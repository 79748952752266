import type { Router } from 'vue-router'
import { configureDynamicParamsMenu } from '../helper/menuHelper'
import type { Menu } from '../types'
import { PermissionModeEnum } from '@/enums/appEnum'
import { useAppStoreWithOut } from '@/store/modules/app'

import { usePermissionStoreWithOut } from '@/store/modules/permission'
import { useDictStoreWithOut } from '@/store/modules/dict'
const maxQuery = 3;//最大请求次数
let query = 0;
const validateDict = async ()=>{
    if(query === maxQuery) return console.error('字典接口报错,请联系管理员');
    const dictStore = useDictStoreWithOut();
    if(!dictStore.getDictMap || !Object.keys(dictStore.getDictMap)?.length){
     query++;
     console.log(`请求字典接口${query}次`)
     await dictStore.setDictMap(true);
     return await validateDict()
    }
    else return true
}

export function createParamMenuGuard(router: Router) {
  const permissionStore = usePermissionStoreWithOut()
  router.beforeEach(async (to, _, next) => {
    if(to.name!=='Login') await validateDict();
    // filter no name route
    if (!to.name) {
      next()
      return
    }

    // menu has been built.
    if (!permissionStore.getIsDynamicAddedRoute) {
      next()
      return
    }

    let menus: Menu[] = []
    if (isBackMode())
      menus = permissionStore.getBackMenuList
    else if (isRouteMappingMode())
      menus = permissionStore.getFrontMenuList

    menus.forEach(item => configureDynamicParamsMenu(item, to.params))

    next()
  })
}

function getPermissionMode() {
  const appStore = useAppStoreWithOut()
  return appStore.getProjectConfig.permissionMode
}

function isBackMode() {
  return getPermissionMode() === PermissionModeEnum.BACK
}

function isRouteMappingMode() {
  return getPermissionMode() === PermissionModeEnum.ROUTE_MAPPING
}
