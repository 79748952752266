import type { App } from 'vue'
import * as Sentry from '@sentry/vue'

export function setupSentry(app: App<Element>) {
  const { VITE_SENTRY_REPLAY, VITE_SENTRY_PROJECT, VITE_SENTRY_URL } = import.meta.env
  Sentry.init({
    app,
    dsn: VITE_SENTRY_URL,
    release: `${VITE_SENTRY_PROJECT}@${__APP_INFO__.pkg.version}`,
    environment: import.meta.env.MODE,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/192\.168\.116\.89:9080\/admin-api/],
    // Session Replay
    replaysSessionSampleRate: VITE_SENTRY_REPLAY ? 0.1 : 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: VITE_SENTRY_REPLAY ? 1.0 : 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
