export enum PageEnum {
  // basic login path
  BASE_LOGIN = '/login',
  // basic home path
  BASE_HOME = '/home/home',
  // error page path
  ERROR_PAGE = '/exception',
  // error log page path
  ERROR_LOG_PAGE = '/error-log/list',
  MESSAGE_PAGE = '/profile/notify-message',
  // 关于我们界面
  ABOUNT_PAGE = '/agreement/about',
  // 服务条款
  SERVICE_PAGE = '/agreement/service',
  // 隐私协议
  PRIVACY_PAGE = '/agreement/privacy'
}

export const PageWrapperFixedHeightKey = 'PageWrapperFixedHeight'
