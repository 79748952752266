<!--
 * @Author: liujing07 1309701492@qq.com
 * @Date: 2024-05-21 13:14:33
 * @LastEditors: liujing07 1309701492@qq.com
 * @LastEditTime: 2024-05-21 14:48:45
 * @FilePath: \youxue-miniapp-clientd:\项目\youxue-admin-client\src\components\Application\src\AppLogo.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<script lang="ts" setup>
import { computed, unref } from 'vue'
import { useGlobSetting } from '@/hooks/setting'
import { useGo } from '@/hooks/web/usePage'
import { useMenuSetting } from '@/hooks/setting/useMenuSetting'
import { useDesign } from '@/hooks/web/useDesign'
import { PageEnum } from '@/enums/pageEnum'
import { useUserStore } from '@/store/modules/user'
import { chinesetoUpperCase } from '@/utils/format'
const props = defineProps({
  // 当前父组件的主题
  theme: { type: String, validator: (v: string) => ['light', 'dark'].includes(v) },
  // 是否显示标题
  showTitle: { type: Boolean, default: true },
  // 折叠菜单时也会显示标题
  alwaysShowTitle: { type: Boolean },
})

const { prefixCls } = useDesign('app-logo')
const { getCollapsedShowTitle } = useMenuSetting()
const { title } = useGlobSetting()
const go = useGo()
const adminLogoPic = useUserStore()?.getSystemTenantInfo?.adminLogoPic
const deptName = useUserStore()?.getSystemTenantInfo?.deptName

const getAppLogoClass = computed(() => [
  prefixCls,
  props.theme,
  { 'collapsed-show-title': unref(getCollapsedShowTitle) },
])

const getTitleClass = computed(() => [
  `${prefixCls}__title`,
  {
    'xs:opacity-0': !props.alwaysShowTitle,
  },
])

function goHome() {
  go(PageEnum.BASE_HOME)
}
</script>

<template>
  <div class="anticon" :class="getAppLogoClass" @click="goHome">
    <!-- <img v-show="showTitle" src="@/assets/images/logo.png" alt="logo" class="logo-img"/> -->
    <!-- 管理端logo -->
    <img v-show="showTitle" :src="adminLogoPic" alt="logo" class="logo-img" v-if="adminLogoPic"/>
    <img v-show="!showTitle" :src="adminLogoPic" alt="logo" class="logo-img"/>
    <div v-show="showTitle" class="ml-2 truncate md:opacity-100" :class="getTitleClass">
      <!-- {{ title }} -->
      <!-- <img class='title-img' src="@/assets/images/title.png" alt=""> -->
      <div class="title-text singe-line" :title="deptName">{{ deptName }}</div>
      <div class="title-text-pin-yin singe-line">{{ chinesetoUpperCase(deptName) }}</div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@prefix-cls: ~'@{namespace}-app-logo';

.@{prefix-cls} {
  display: flex;
  align-items: center;
  padding-left: 7px;
  cursor: pointer;
  transition: all 0.2s ease;

  &.light {
    border-bottom: 1px solid var(--border-color);
  }

  &.collapsed-show-title {
    padding-left: 20px;
  }

  &.dark &__title {
    // color: @white;
    color:var(--text-4-color);//修改主题色
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    transition: all 0.5s;
  }
  .anticon{
    text-align:center;
  }
  .logo-img{
    width:40px;
    height: 40px;
    margin-left: 6px;
  }
  .title-img{
    width: 98px;
    height: 28px;
  }
  .title-text {
    max-width: 150px;
    color: hsl(224.4deg 82.42% 35.69%);
    font-size: 22px;
  }
  .title-text-pin-yin {
    color: hsl(224.4deg 82.42% 35.69%);
    font-size: 8px;
  }
}
</style>
