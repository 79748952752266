// token key
export const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN__'

export const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN__'

export const LOGIN_INFO_KEY = 'LOGIN_INFO__'

export const TENANT_INFO_KEY = 'TENANT_INFO__'

export const TENANT_ID_KEY = 'TENANT_ID__'

export const LOCALE_KEY = 'LOCALE__'

// user info key
export const USER_INFO_KEY = 'USER__INFO__'

// role info key
export const ROLES_KEY = 'ROLES__KEY__'

// project config key
export const PROJ_CFG_KEY = 'PROJ__CFG__KEY__'

// dict info key
export const DICT_KEY = 'DICT__KEY__'

// lock info
export const LOCK_INFO_KEY = 'LOCK__INFO__KEY__'

export const MULTIPLE_TABS_KEY = 'MULTIPLE_TABS__KEY__'

export const APP_DARK_MODE_KEY_ = '__APP__DARK__MODE__'

// base global local key
export const APP_LOCAL_CACHE_KEY = 'COMMON__LOCAL__KEY__'

// base global session key
export const APP_SESSION_CACHE_KEY = 'COMMON__SESSION__KEY__'

export enum CacheTypeEnum {
  SESSION,
  LOCAL,
}
